/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import { poll } from 'utilities/poll.js';
import { countMetric } from 'utilities/simpleMetrics.js';

(function () {
  countMetric('legacy/integrations-hubspot-v1', 1, {
    href: window.location.href,
  });

  // make sure _hsq is defined
  if (window._hsq == null) {
    window._hsq = [];
  }

  // Set foreignData on a video
  //
  // The important thing here is that the video has access to hutk so we can
  // send it to the Turnstile endpoint and send it back to HubSpot. This allows
  // them to associate this conversion event with the contact in their system.
  const setForeignData = function (video, tracker) {
    // The URL of the page (sans query string)
    const pageUrl = document.location.href.split('?')[0];

    // Trim whitespace from the page name
    let pageName = __guard__(document.getElementsByTagName('title')[0], (x) => x.innerHTML) || '';
    pageName = pageName.replace(/^[\n\s]+/, '').replace(/[\n\s]+$/, '');
    // XXX: Remove non-ASCII characters because they break base64 encoding and
    // cause our requests to the distillery to fail. There's a better fix, but
    // this isn't super important, so let's just do this for now.
    // eslint-disable-next-line no-control-regex
    pageName = pageName.replace(/[^\x00-\x7F]/g, '');

    return video.foreignData({
      hubspot_hutk: tracker.utk.visitor,
      wistia_visitor_key: video.visitorKey(),
      page_url: pageUrl,
      page_name: pageName,
      canonical_url: tracker.canonicalUrl,
      page_id: tracker.pageId,
      content_type: tracker.contentType,
    });
  };

  const initHubspotIntegration = () =>
    window.wistiaEmbeds.onFind((video) =>
      video.ready(() => {
        // Bail out if the new Hubspot integration is running.
        if (
          video._mediaData.integrations != null ? video._mediaData.integrations.hubspot : undefined
        ) {
          return;
        }

        return poll(
          () => video.visitorKey(),
          () => {
            if (!(video instanceof window.Wistia.PlaylistIframeAPI)) {
              // Once HS is ready, call setForeignData
              window._hsq.push((tracker) => setForeignData(video, tracker));
            }

            if (video instanceof window.Wistia.PublicApi) {
              return window.Wistia.Metrics.videoCount(
                video,
                'player/integrations-hubspot-v1/init-on-api-embed',
              );
            }
            // We don't get the account for iframes, but we can use the hashed
            // ID to get it.
            if (video instanceof window.Wistia.PlaylistIframeAPI) {
              return window.Wistia.Metrics.count(
                'player/integrations-hubspot-v1/init-on-iframe-playlist',
                1,
                {
                  href: location.href,
                  referrer: document.referrer,
                  hashedId: video.hashedId(),
                },
              );
            }
            return window.Wistia.Metrics.count(
              'player/integrations-hubspot-v1/init-on-iframe-embed',
              1,
              {
                href: location.href,
                referrer: document.referrer,
                hashedId: video.hashedId(),
              },
            );
          },
        );
      }),
    );

  if (window.wistiaEmbeds != null) {
    // the embed shep is already here, just do our stuff
    return initHubspotIntegration();
  }
  if (window.wistiaEmbedShepherdReady != null) {
    // the embed shep ready fn is already here, chain onto it
    const originalReady = window.wistiaEmbedShepherdReady;
    window.wistiaEmbedShepherdReady = function () {
      originalReady();
      return initHubspotIntegration();
    };
  } else {
    // fresh pow
    window.wistiaEmbedShepherdReady = initHubspotIntegration;

    // include embed shep
    const es = document.createElement('script');
    es.type = 'text/javascript';
    es.async = true;
    es.src = '//fast.wistia.com/assets/external/embed_shepherd-v1.js';
    const s = document.getElementsByTagName('script')[0];
    return s.parentNode.insertBefore(es, s);
  }
})();

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
}
